import React, { useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';

import { useHelpCenter, EventEmitter } from '@worklist-2/core/src';
import ListItemLink from '../../ListItemLink/ListItemLink';
import { useTranslation } from 'react-i18next';

// libraries
import _ from 'lodash';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useLocation } from 'react-router';

const NavLink = ({ label, icon, iconSvg, path, subNavLink, activeLink }) => {
	const { t } = useTranslation('helpCenter');
	const { currentSideTab, setCurrentSideTab, getOmegaAIHelpIcon } = useHelpCenter();
	const [open, setOpen] = useState(false);
	const [pushState, setPushState] = useState({
		selected: false,
		selectedSubIndex: -1,
	});

	useEffect(() => {
		EventEmitter.subscribe('pushState', data => {
			setPushState({
				selected: path !== '' && data.path?.includes(path),
				selectedSubIndex: !subNavLink
					? -1
					: _.findIndex(subNavLink, function (link) {
							return link.path?.includes(data.path);
					  }),
			});
		});
	}, []);

	const tabSelected = useMemo(() => {
		return (
			path === activeLink?.path ||
			activeLink?.path?.includes(path) ||
			pushState.selected ||
			currentSideTab === path ||
			(path !== '' && currentSideTab && currentSideTab.includes(path))
		);
	}, [path, activeLink, pushState]);

	useEffect(() => {
		if (tabSelected) {
			setOpen(true);
		}
	}, [tabSelected, activeLink, subNavLink]);

	const handleClick = () => {
		setOpen(!open);
	};
	return (
		<>
			<ListItemLink
				key={path}
				to={`/help/${path}`}
				id={`link-${path}`}
				className="side-nav-link-item"
				sx={{
					'& svg': {
						px: '20px',
					},
				}}
				primary={t(label)}
				isParent={true}
				iconComponent={getOmegaAIHelpIcon(icon, iconSvg)}
				onClick={() => {
					setCurrentSideTab(path);
					handleClick();
					EventEmitter.dispatch('pushState', {
						path: `/help/${path}`,
					});
				}}
				tabSelected={tabSelected}
				collapse={!!subNavLink && subNavLink.length > 0 ? true : false}
				expandIcon={open}
			/>
			<Collapse
				in={open}
				timeout="auto"
				unmountOnExit
				sx={{
					backgroundColor: tabSelected ? 'rgba(39, 39, 39, 0.16)' : 'transparent',
				}}
			>
				{!!subNavLink &&
					subNavLink.map((link, i) => (
						<ListItemLink
							key={link.path}
							to={`/help/${link.path}`}
							id={`link-${link.path}`}
							className="side-nav-link-item"
							primary={t(link.label)}
							iconComponent={getOmegaAIHelpIcon(link.icon, iconSvg)}
							onClick={() => {
								setCurrentSideTab(link.path);
								EventEmitter.dispatch('pushState', {
									path: `/help/${link.path}`,
								});
							}}
							tabSelected={activeLink?.path === link.path || pushState.selectedSubIndex === i}
							addIcon={true}
							sx={{ paddingLeft: '25px' }}
						/>
					))}
			</Collapse>
		</>
	);
};

const HelpSideBar = ({ navLinks }) => {
	const params = useLocation();

	const flattenedLinks = useMemo(() => {
		const flatLinks = [];
		_.forEach(navLinks, function (link) {
			flatLinks.push(link);
			if (link?.subNavLink) {
				_.forEach(link.subNavLink, function (subLink) {
					flatLinks.push(subLink);
				});
			}
		});
		return flatLinks;
	}, [navLinks]);

	const activeLink = useMemo(() => {
		try {
			const { pathname } = params;
			const paths = pathname?.split('/');
			const active = _.find(flattenedLinks, function (link) {
				let splitLink = link.path?.split('/');
				let matched = splitLink[splitLink.length - 1] === paths[paths.length - 1];
				if (!matched) {
					_.forEach(link.tabs, function (tab) {
						splitLink = tab.path?.split('/');
						if (pathname.toLowerCase().includes(tab.path.toLowerCase())) {
							matched = true;
						}
					});
				}
				return matched;
			});
			return active;
		} catch (error) {
			return null;
		}
	}, [params, flattenedLinks]);

	return (
		<Grid item width={359}>
			<Grid
				container
				direction={'column'}
				height={'100%'}
				minHeight={640}
				spacing={0}
				wrap={'nowrap'}
				sx={{ maxHeight: 'calc(100vh - 160px)', overflow: 'auto' }}
			>
				<Paper
					className="sidebar scroll-bar-wrap"
					style={{
						minHeight: 132,
						width: '100%',
						textOverflow: 'ellipsis',
						padding: '20px 0px 15px 0px',
						borderRadius: 10,
						flex: 3,
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<List style={{ width: '360px', margin: 0 }}>
						<SimpleBar
							className="scroll-box"
							style={{
								height: '100%',
								width: '101%',
								overflowX: 'hidden',
							}}
						>
							{navLinks?.map((link, i) => (
								<NavLink role="mainNavigation" key={i} {...link} activeLink={activeLink} />
							))}
						</SimpleBar>
					</List>
				</Paper>
			</Grid>
		</Grid>
	);
};
export default HelpSideBar;
