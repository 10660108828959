//libs
import { v4 as uuid } from 'uuid';
import { generateRandomString, fhirExtensionUrls } from '@worklist-2/core/src';
import { isEmpty } from 'lodash';

export const createUrnUuid = () => {
	const uniqueId = uuid();
	return 'urn:uuid' + uniqueId;
};

export function convertTelecom(input, corporateWebsite) {
	let output = [];

	// Mapping from system names to ranks when corporateWebsite is not present
	let systemRanks = {
		email: 1,
		phone: 2,
		fax: 3, // Assuming fax is present in the input, adjust if needed
	};

	// If corporateWebsite is present, it should be inserted as rank 1
	if (corporateWebsite) {
		systemRanks = {
			url: 1,
			email: 2,
			phone: 3,
			fax: 4, // Adjust ranks accordingly
		};

		// Add corporateWebsite to the output as rank 1
		output.push({
			rank: 1,
			system: 'url',
			value: corporateWebsite,
			__uniqueId: generateRandomString(16),
		});
	}

	// Iterate over each key in the input
	for (const system in input) {
		// Check if the system is present in the mapping
		if (systemRanks.hasOwnProperty(system)) {
			const rank = systemRanks[system];
			const value = system === 'phone' ? input?.[system]?.replace(/\D/g, '') : input?.[system];
			const uniqueId = generateRandomString(16);

			output.push({
				rank: rank,
				system: system,
				value: value,
				__uniqueId: uniqueId,
			});
		}
	}

	return output;
}

export const formatName = clonedPractitioner => {
	return isEmpty(clonedPractitioner)
		? []
		: [
				{
					given: [clonedPractitioner.physicianFirstName],
					family: clonedPractitioner.physicianLastName,
					text: `${clonedPractitioner.physicianFirstName} ${clonedPractitioner.physicianLastName}`,
					suffix: [clonedPractitioner.suffix],
					prefix: [clonedPractitioner.prefix],
				},
		  ];
};

export function convertAddress(input) {
	let output = [];

	// Iterate over each address in the input
	for (const address of input) {
		// Create the new object with the required properties
		const newAddress = {
			line: address.line,
			city: address.city,
			state: address.state,
			postalCode: address.postalCode || '', // Use an empty string if postalCode is not present
			country: address.country,
		};

		// Push the new object to the output array
		output.push(newAddress);
	}

	return output;
}

export function convertPractitioner(input) {
	let output = [];

	// Map loginEmail to the extension format
	output.push({
		url: fhirExtensionUrls.practitioner.loginName,
		valueString: input?.['loginEmail'] ?? '',
	});

	// Map isUserActive to the extension format
	output.push({
		url: fhirExtensionUrls.practitioner.isUserActive,
		valueBoolean: true,
	});

	// Map dea to the extension format
	output.push({
		url: fhirExtensionUrls.license.dea,
		valueString: input?.['dea'] ?? '',
	});

	// Map specialty to the extension format

	output.push({
		url: fhirExtensionUrls.license.specialty,
		valueReference: input?.['specialty'] || input?.['specialty']?.valueReference || {},
	});
	output.push(
		{
			url: fhirExtensionUrls.practitioner.imageViewerUrl,
			valueString: '',
		},
		{
			url: fhirExtensionUrls.practitioner.documentViewerUrl,
			valueString: '',
		}
	);
	if (input?.referringOrganization) {
		output.push({
			url: fhirExtensionUrls.common.organization,
			valueReference: {
				display: input?.['referringOrganization']?.display,
				id: input?.['referringOrganization']?.id,
				reference: `Organization/${input?.['referringOrganization']?.id}`,
			},
		});
	}
	return output;
}

export const createAddUserPayload = (practitionerJson, selectedRefOrganizationRole) => {
	const clonedPractitioner = { ...practitionerJson };
	const payload = {};
	const practitionerUrnUuid = createUrnUuid();
	payload.name = formatName(clonedPractitioner);
	payload.telecom = isEmpty(clonedPractitioner?.telecom) ? [] : convertTelecom(clonedPractitioner?.telecom);
	payload.address = isEmpty(clonedPractitioner?.address) ? [] : convertAddress(clonedPractitioner?.address);
	payload.gender = 'male';
	payload.extension = isEmpty(clonedPractitioner) ? [] : convertPractitioner(clonedPractitioner);
	payload.identifier = [
		{
			value: clonedPractitioner.npi || '',
			system: '',
			type: {
				coding: [
					{
						system: 'http://hl7.org/fhir/ValueSet/identifier-type',
						code: 'NPI',
					},
				],
			},
			use: 'usual',
		},
	];

	// Need to make a bundle call to both practitioner and practitioner role
	const practitionerPayload = {
		fullUrl: practitionerUrnUuid,
		request: {
			method: 'POST',
			url: '/Practitioner/',
		},
		resource: {
			...payload,
			active: true,
			birthDate: null,
			resourceType: 'Practitioner',
		},
	};

	// Will need to loop through selected roles to create payload for each of them and
	// append them to entry
	const practitionerRolePayloads = {
		fullUrl: createUrnUuid(),
		request: { method: 'POST', url: '/PractitionerRole/' },
		resource: {
			active: true,
			extension: [
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/role',
					valueReference: {
						id: selectedRefOrganizationRole?.id,
						reference: `Role/${selectedRefOrganizationRole?.id}`,
						display: 'REFERRING PHYSICIAN',
					},
				},
				{
					url: 'http://www.ramsoft.com/fhir/extension/practitioner/studyPlayerType',
					valueCoding: {
						code: '1',
						display: 'Referring Physician',
					},
				},
			],
			organization: {
				display: clonedPractitioner?.managingOrganization?.name,
				id: clonedPractitioner?.managingOrganization?.id,
				identifier: '',
				reference: `Organization/${clonedPractitioner?.managingOrganization?.id}`,
			},
			practitioner: {
				display: '',
				reference: practitionerUrnUuid,
			},
			resourceType: 'PractitionerRole',
		},
	};

	return {
		entry: [practitionerPayload, practitionerRolePayloads],
		id: 'bundle-transaction',
		resourceType: 'bundle',
		type: 'transaction',
	};
};
