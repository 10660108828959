import React, { createContext, useContext, useState } from 'react';

import { fhirExtensionUrls } from '@worklist-2/core/src';

const GlobalContext = createContext({});

const GlobalContextProvider = ({ children }) => {
	const [patient, setPatient] = useState(null);
	const [diagnosticReports, setDiagnosticReports] = useState();
	const [documentReferences, setDocumentReferences] = useState();
	const [studies, setStudies] = useState();
	const [practitionerSignature, setPractitionerSignature] = useState();
	const [proxySignature, setproxySignature] = useState(null);
	const [proxyUser, setProxyUser] = useState(null);
	const [practitioner, setPractitioner] = useState();
	const [organizationTimezone, setOrganizationTimezone] = useState();
	const [studyShareQRLink, setStudyShareQRLink] = useState();
	const [practitioners, setPractitioners] = useState([]);
	const [practitionerRoles, setPractitionerRoles] = useState([]);
	const [practionerLoaded, setPractionerLoaded] = useState(false);
	const [proxyPractionerLoaded, setProxyPractionerLoaded] = useState(false);
	const [forms, setForms] = useState([]);
	const [isShownBackdrop, setIsShownBackdrop] = useState(false);

	const practitionerUserType = (practitioner?.extension || []).find(
		ext => ext.url === fhirExtensionUrls.practitioner.userType
	);
	const isReferringPhysicianType = (practitionerUserType?.extension || []).find(
		ext => ext.url === fhirExtensionUrls.practitioner.userTypeIsReferringPhysician
	);
	const isReferringPhysician = Boolean(isReferringPhysicianType?.valueBoolean);

	return (
		<GlobalContext.Provider
			value={{
				patient,
				setPatient,
				diagnosticReports,
				setDiagnosticReports,
				documentReferences,
				setDocumentReferences,
				studies,
				setStudies,
				practitionerSignature,
				setPractitionerSignature,
				isReferringPhysician,
				practitioner,
				setPractitioner,
				organizationTimezone,
				setOrganizationTimezone,
				studyShareQRLink,
				setStudyShareQRLink,
				practitioners,
				setPractitioners,
				forms,
				setForms,
				proxySignature,
				setproxySignature,
				proxyUser,
				setProxyUser,
				practitionerRoles,
				setPractitionerRoles,
				isShownBackdrop,
				setIsShownBackdrop,
				practionerLoaded,
				setPractionerLoaded,
				proxyPractionerLoaded,
				setProxyPractionerLoaded,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalContext, useGlobalContext, GlobalContextProvider as default };
